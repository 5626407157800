import Container from '@mui/material/Container';
import './LetsPlayV2.css';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StarshineHeader } from '../../components/StarshineHeader';
import { Artifact, Target, useConfigQuery } from '../../api/settings';
import { s3_url_from_path } from '../../baseAPI';
import { translate } from '../../api/LocalizedString';
import { getTargetOs, TargetOs } from '../../utils/platform';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';


const buttonSX = { my: 2, color: 'black', display: 'block' };


export default function LetsPlayV2() {
  const { t } = useTranslation();
  const [target, setTarget] = useState<TargetOs | null>(null);
  const [artifact, setArtifact] = useState<Artifact | null | undefined>(undefined); // undefined is for "loading" state
  const [artifactModal, setArtifactModal] = useState<null | Artifact>(null);
  const settingsQuery = useConfigQuery('downloading-page');

  useEffect(() => {
    let os = getTargetOs();
    console.log('OS', os);

    if (settingsQuery.data) {
      let a = findTarget(os, settingsQuery.data.targets);
      console.log('Found artifact', a);
      setArtifact(a);
    }

    setTarget(os);
  }, [settingsQuery]);

  return <div style={{ background: '#222', minHeight: '80vh' }}>
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={artifactModal !== null}
      onClose={() => {
        setArtifactModal(null);
      }}
      closeAfterTransition
    >
      {artifactModal !== null ? <Box maxWidth="md" id='explanation-modal' className='modal-content'>
        <Typography mb='1em' variant='h6' component='h2'>
          {t('Instructions')}
          <span id="close-icon" onClick={() => setArtifactModal(null)} style={{ float: 'right' }}>
            <CloseIcon />
          </span>
        </Typography>

        <Typography id='explanation-body' component='p'>
          <ol>
            {artifactModal.instructions.map((a, i) => {
              return <li>{translate(a)}</li>;
            })}
          </ol>
        </Typography>
      </Box> : <div></div>}
    </Modal>

    <Container id='main-downloading-section' maxWidth='md'>
      <img src='/launcher-app.png' title='os' alt='block' />
      {artifact === undefined ? t('Loading') : null}
      {artifact === null ?
        <Button disabled color='success' variant='contained' sx={buttonSX}>{t('Unknown OS')}</Button> : null}
      {artifact !== null && artifact !== undefined ?
        <a href={s3_url_from_path(artifact.file)} target='_blank' download>
          <Button color='success' variant='contained' sx={buttonSX}>{t('Download Launcher')}</Button>
        </a> : null}

      <Container id='main-downloading-section-explanation' maxWidth='sm'>
        {getTargetOsDescription(getTargetOs())}
        <br />
        <br />
      </Container>
      <Container id='show-instructions-button' maxWidth='sm'>
        {
          artifact !== null && artifact !== undefined
            ? <span onClick={() => {setArtifactModal(artifact)}}>{t('Instructions')}</span>
            : null
        }
      </Container>
    </Container>;
    <StarshineHeader message={t('OS specific launcher versions')}></StarshineHeader>;
    <Container id='os-specific-downloading-section' maxWidth='md'>
      {settingsQuery.data !== undefined && settingsQuery.data.targets.map((a, i) => {
        return (
          <div key={i} className='os-specific-download-section'>
            <img src={s3_url_from_path(a.icon)} alt='block' />
            {/*<Container className='os-specific-section-span' maxWidth='sm'>*/}
            {/*  {a.name}*/}
            {/*</Container>*/}
            <div className='platform-artifacts'>
              {a.artifacts.map((artifact, i) => {
                return (
                  <div key={i}>
                    <h4 className='os-specific-section-span'>
                      {artifact.name}
                    </h4>
                    <p>
                      {translate(artifact.short_description)}
                    </p>
                    <a download href={s3_url_from_path(artifact.file)}>
                      {t('Download')}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Container>;
  </div>
    ;
}


function findTarget(os: TargetOs, targets: Target[]): Artifact | null {
  let target;
  switch (os) {
    case TargetOs.WINDOWS:
      target = targets.find((a) => a.name.toLowerCase().includes('windows'));
      return target === undefined ? null : target.artifacts[0];
    case TargetOs.MAC_OS:
      target = targets.find((a) => a.name.toLowerCase().includes('mac'));
      return target === undefined ? null : target.artifacts[0];
    case TargetOs.LINUX:
      target = targets.find((a) => a.name.toLowerCase().includes('linux'));
      return target === undefined ? null : target.artifacts[0];
    case TargetOs.ANDROID:
    case TargetOs.IOS:
    case TargetOs.UNKNOWN:
      return null;
  }
}


function getTargetOsDescription(os: TargetOs): string {
  switch (os) {
    case TargetOs.WINDOWS:
      return i18next.t('Download installer for OS windows');
    case TargetOs.MAC_OS:
      return i18next.t('Download app for Mac OS');
    case TargetOs.LINUX:
      return i18next.t('Download App Image for Linux');
    case TargetOs.ANDROID:
      return i18next.t('Android is not supported. PC only');
    case TargetOs.IOS:
      return i18next.t('IOs is not supported. PC only');
    default:
      return i18next.t('Unknown OS');
  }
}
