import { baseAPI_V2 } from '../baseAPIV2';
import { useQuery } from 'react-query';
import { LocalizedString } from './LocalizedString';


export interface Artifact {
  id: string
  name: string
  targets: string[]
  file: string
  short_description: LocalizedString
  instructions: LocalizedString[]
}

export interface Target {
  name: string
  icon: string
  artifacts: Artifact[]
}

export interface Settings {
  targets: Target[]
}

export async function getConfig(type: string) {
  return await baseAPI_V2.get<Settings>(`/api/v2/settings/${type}/`);
}

// useQuery wrapper
export function useConfigQuery(type: string) {
  return useQuery(
    ['download-page-settings'],
    () => getConfig(type).then((r) => r.data), {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
};
