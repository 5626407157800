

export enum TargetOs {
  WINDOWS = 'windows',
  MAC_OS = 'mac_os',
  LINUX = 'linux',
  ANDROID = 'android',
  IOS = 'ios',
  UNKNOWN = 'unknown'
}

export enum TargetArch {
  X86_64 = 'x86_64',
  X86 = 'x86',
  AARCH64 = 'aarch64',
  UNKNOWN = 'unknown'
}

export function getTargetOs(): TargetOs {
  let user_agent = window.navigator.userAgent;

  if (user_agent.includes("Windows")) return TargetOs.WINDOWS;
  if (user_agent.includes("Mac OS")) return TargetOs.MAC_OS;
  if (user_agent.includes("Linux")) return TargetOs.LINUX;
  if (user_agent.includes("Android")) return TargetOs.ANDROID;
  if (user_agent.includes("iPod touch")) return TargetOs.IOS;
  if (user_agent.includes("iPhone")) return TargetOs.IOS;
  if (user_agent.includes("Macintosh")) return TargetOs.IOS;

  return TargetOs.UNKNOWN
}