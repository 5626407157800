

export interface LocalizedString {
  EN: string | null | undefined,
  RU: string | null | undefined,
  UK: string | null | undefined,
}

export function translate(ls: LocalizedString): string {
  const lang = getBrowserLanguageAsASimpleString();
  const fallbackToEnglish = (ls.EN === undefined || ls.EN === null) ? "" : ls.EN;

  switch (lang) {
    case "en": return fallbackToEnglish;
    case "ru": return (ls.RU === undefined || ls.RU === null) ? fallbackToEnglish : ls.RU;
    case "uk": return (ls.UK === undefined || ls.UK === null) ? fallbackToEnglish : ls.UK;
    default: {
      return fallbackToEnglish;
    }
  }
}

function getBrowserLanguageAsASimpleString(): string {
  try {
    const userLang = navigator.language.toLowerCase();
    if (userLang.includes("-")) return userLang.split("-")[0]

    return userLang
  } catch (e) {
    console.error("Failed to determine browser language. Fall back to 'en'", e);
    return 'en'
  }
}